#skills {
    overflow: hidden;
    width: 100vw;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    
}

.skillTitle{
    font-size: 35px;
    font-weight: 600;
    padding-top: 3rem;
    letter-spacing: 1.5px;
    margin-bottom: 1.5rem;
}
.actualSkills{
    font-weight: 200;
    font-size: 1rem;
    max-width: 55rem;
    padding: 0 2rem;

}

.skillBars{
    margin: 1.5rem;
    width: 100vw;
    max-width: 80%;
    display: flex;
    flex-wrap: wrap;
    gap: 11px;
}

.skillBar{
    /* display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(244, 233, 206);
    border-radius: 50px;
    border:  3px solid rgb(144, 175, 142);
    padding: 20px;
    gap: 11px;
    width: 20px; */
}
.skillBarImg{
    height: 100px;
    object-fit: contain;
    width: 150px;
    max-width: 150px;
    
}

@media screen and (max-width: 720px){
    .skillBarImg{
        height: 50px;
    }
}