

.contactTitle{
    font-size: 35px;
    z-index: 2;
}
.contenDesc{
    font-size: 18px;
    font-weight: 300;
    z-index: 2;
    
}
.contactForm{
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50vw;
    max-width: 60rem;
    color: white;
    z-index: 1;
}

.contactForm ::placeholder{
    color: white;
    z-index: 1;
}

.nameC,.email, .msg{
    font-size: medium;
    width: 70%;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    color: white;
    border: none;
    border-radius: 0.5rem;
    background: rgb(106, 106, 106);
    z-index: 1;
}



.links{
    display: flex;
    flex-wrap: wrap;
    z-index: 1;
}
.link{
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin: 0 0.75rem;
}
.link1{
    position: relative;
    object-fit: cover;
    height: 2.4rem;
    width: 2.4rem;
    top: 4px;
    margin: 0 0.75rem;
    z-index: 1;
}

.footer {
    width: 110vw;
    height: 100%;
    /* padding-top: 100px; */
    background-color: rgb(106, 106, 106);
    position: relative;
    top: 250px;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-size: small;
    /* margin: 1rem; */
}
