.container{
    margin-top: 200px;
    color: black;
    margin-left: 10% ;
    margin-right: 10% ;
    padding-bottom: 100px;
    
}
.titleP{
    font-size: 35px;
    display: flex;
    font-weight: 700;
    letter-spacing: 1.5px;
    text-align: center;
    justify-content: center;
}
.projects{
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 30px;
    width: 100%;
}
.topContainer{
    background: hsl(35, 16%, 53%);
    border-radius: 10px;
    

}

.pContainer{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: rgb(238, 225, 193);
    /* box-shadow: 10px 16px 14px 0 rgb(115, 108, 103); */
    transition: 0.5s;
    padding: 18px 24px;
    max-width: 430px;
    height: 470px;
    max-height: 470px;
    transform: translateY(-9px) translateX(-9px);
    
}
.pContainer:hover {
    transform: translateY(0px) translateX(0px);
}
.pSkills{
    width: 230px;
    margin-top: 14px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.pDesc{
    font-size: 14px;
    padding: 5px;
    width: 100%;
}

.pTitle{
    padding: 5px;
}

.skill{
    font-size: 15px;
    font-weight: 400;
    border-radius: 100px;
    background: rgb(189, 207, 188);
    padding: 2px 22px;
    display: flex;
    width: 90px;
    justify-content: center ;
    align-items: center;
    transform: translateY(-6px);
}
.topSkill{
    background: hsl(132, 85%, 21%);
    border-radius: 100px;
    outline-offset: 4px;
    display: flex;
    height: min-content;

}
.topSkill:active .skill {
    transform: translateY(-2px);
    
  }
.links2{
    background: hsl(340deg 100% 32%);
    border-radius: 12px;
    cursor: pointer;
    width: 90px;
    display: flex;
    margin-left: 300px;
    
    
}
.link3{
    display: flex;
    width: 90px;
    justify-content: center ;
    background: hsl(345deg 100% 47%);
    transform: translateY(-6px);
    font-size: 20px;
    text-decoration: none;
    font-weight: 600;
    border-radius: 10px;
    padding: 1px 22px;
    color: black;
}
.links2:active .link3 {
    transform: translateY(-2px);
    
  }

.portfolioImg{
    max-height: 230px;
    width: fit-content;

    border: 9px solid rgb(184, 183, 183);
    border-left: 30px solid rgb(184, 183, 183);
    border-right: 30px solid rgb(184, 183, 183);
    border-radius: 10px;
}

@media screen and (max-width: 720px){
    .pContainer{
        max-width: 330px;
        height: 470px;
        max-height: 400px;
    }
    .pDesc{
        font-size: 12px;
    }
    .links2{
        margin-left: 200px;
        
    }
}