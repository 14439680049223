.navbar{
    height: 5rem;
    width: 100%;
    /* max-width: 85rem; */
    margin:auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    border-radius: 10;
    z-index: 3;
    text-align: center;
    transition:  0.5s;
    background-color: rgb(144, 175, 142);
    /* overflow-x: hidden; */
}
.menu{
    margin: 0 auto;
    display: flex;
   
    
}

.item{
    font-size: 21px;
    margin: 5rem ;
    cursor :pointer;
    transition:  0.4s;
    
}
.navbar:hover{
    background-color: rgb(104, 141, 104)
}
.item:hover{
    color: rgb(244, 240, 221);
    padding-bottom: 0.5rem;
    border-bottom: 3px solid rgb(244, 240, 221);

}

.mobMenu{
    display: none;
    height: 40px;
}

/* .active{
    color: rgb(244, 240, 221);;
} */

@media screen and (max-width: 720px){
    .mobMenu{
        display: flex;
    }
    .menu{
        display: none;
    }
    
    
}