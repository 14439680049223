
#intro{
   
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    left: 100px;
    font-family: "Light";
}
.logo{

    width: 40rem;
    padding: 5rem;
    object-fit: cover;
    z-index: -1;
}
.introContent{
    /* height: 100vw;
    width: 100vw; */
    left: 41rem;
    bottom: 14rem;
    position: relative;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2rem;
    
}
.title{
    /* bottom: 4rem; */
    position: relative;
    font-size: 1.75rem;
    font-weight: 100;
    color: rgb(106, 106, 106);
}
.about{
    /* bottom: 6rem; */
    position: relative;
    font-size: 17px;
    max-width: 45rem;
    font-weight: 100;
    letter-spacing: 1px;
}

.begin{
    height: 22rem;
}


.name{
    color: rgb(144, 175, 142);
    font-size: 3rem;
}

::-moz-selection {
    background: #AFAFAF;
  }
  ::-webkit-selection {
    background: #AFAFAF;
  }
  ::selection {
    background: rgb(74, 109, 72);
    color: rgb(192, 229, 192);
    
  }
  
  @media screen and (max-width: 720px){
    .logo{
        display: flex;
    }
    .name{
       font-size: 1.8rem;
       display: flex;
    }
    
    
}
/* @font-face {
font-family: "Light";   /*Can be any text*/
/* src: local("blow"),
    url("../fonts/blow.ttf") format("truetype");
    */